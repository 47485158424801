import React, {useState} from 'react'
import './css/portfolio.css'
import SectionTitle from './SectionTitle'
import Realization from './Realization'
 
import Button from './Button'

import myData from '../myData.json'
//console.log(myData);
const Portfolio = () => {

   const [showItems, setShowItems] = useState(16)
   const data = myData.slice(0,showItems);

   const datanumber = myData.length;

   const handleOnClick = () => {
      setShowItems(showItems+4);
   }

  return (
     <div className='portfolio section' id="portfolio">
       <div className='container'>
         <SectionTitle title="Portfolio"/>
         <div className='realizations'>
         {data.map(item =>
            <Realization key={item.title}
               title={item.title}
               img={item.img}
               info={item.info}
               url={item.url}
            /> 
        ) } 
         </div>
         {
         (data.length<datanumber) &&
         <Button className="btn mt-50" txt="Zobacz więcej" onClick={handleOnClick}/>
          }
         </div>
     </div>
  )
}

export default Portfolio